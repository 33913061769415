@import '../../styles/importer';

.rc-slider {
  height: 24px;
  padding: 10px 0;
}

.rc-slider-rail {
  border: 1px solid $border-color;
  background: #f0f0f0;
}

.rc-slider-track {
  background-color: $secondary-color;
}

.rc-slider-handle {
  width: 17px;
  height: 24px;
  border: 1px solid $border-color;
  border-radius: 2px;
  margin-top: -8.5px;
  background: #f9f9f9;
}
