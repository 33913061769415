@import 'variables';

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$mobile-width + 1}) {
    @content;
  }
}

@mixin layer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

@mixin click {
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }
}

@mixin click-text {
  &:hover {
    color: $secondary-color;
  }

  &:active {
    color: $secondary-color;
    transform: translateY(1px);
  }
}

/* Allows for text to be selected */
@mixin selectable {
  user-select: text;

  &::selection {
    background-color: $selection;
    color: $primary-color;
  }
}

// content: attr(data-parent);

@mixin flexcenter-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flexcenter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin flex-no-grow {
  flex-grow: 0;
  flex-shrink: 0;
}

@mixin horizontal-scroll {
  overflow: scroll hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin background-responsive {
  background-position: center;
  background-size: cover;
}

// Fills the container element
@mixin fill-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

@mixin align-horizontal {
  margin-right: auto;
  margin-left: auto;
}

@mixin align-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin hypens {
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-before: 2;
  -webkit-hyphenate-limit-lines: 2;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

@mixin content-container {
  @include flexcenter-column;

  width: 100%;
  max-width: $container-width;
  height: 100%;
  justify-content: space-between;
  word-break: keep-all;

  @include desktop {
    height: $container-height;
    max-height: $container-max-height;
  }

  @include mobile {
    max-height: $container-height;
  }

  small {
    width: 100%;
    margin: 0;
    margin-top: 10px;
    text-align: right;
  }

  .copy {
    @include flexcenter-column;

    width: 100%;
    height: 100%;
    flex-grow: 2;
    align-items: stretch;
    margin: 1em;

    ul,
    p {
      padding: 0;
      margin: 0;
    }

    ul {
      list-style: inside;
    }
  }

  .copyNoMargin {
    @include flexcenter-column;

    width: 100%;
    height: 100%;
    flex-grow: 2;
    align-items: stretch;
    margin: 0 1em 1em;

    ul,
    p {
      padding: 0;
      margin: 0;
    }

    ul {
      list-style: inside;
    }
  }
}
