@import '../../styles/importer';

.button {
  width: 100%;
  height: 48px;
  padding: 10px 15px;
  border: none;
  background: $secondary-color;
  color: $background-color;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;

  @include mobile {
    font-size: 0.9em;
  }

  &.white {
    border: 1px solid $primary-color;
    background: $background-color;
    color: $primary-color;
  }

  &.black {
    background: $primary-color;
  }

  &.warning {
    background: $warning-color;
  }

  &.small {
    flex-grow: unset;
    flex-shrink: unset;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;

    &:active {
      transform: translateY(0);
    }
  }

  @include click;
  @include flex-no-grow;
}
