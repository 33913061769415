@import '../../styles/importer';

.control {
  display: flex;
  width: 100%;
  max-width: 345px;
  flex-direction: column;

  h2 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-transform: uppercase;

    @include mobile {
      margin-bottom: 5px;
    }
  }

  h3 {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  h4 {
    color: #000;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  h2,
  h3,
  h4 {
    color: $primary-color;
    letter-spacing: 0;
  }
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionsVertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
