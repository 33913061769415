.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #6e6e6e;
  cursor: default;
  font-family: 'Work Sans', sans-serif;
  font-size: 10px;
  font-weight: 400;
  gap: 4px;
  letter-spacing: 0.18px;
  line-height: 28.134px;
}

.icon {
  display: flex;
  width: 1.3333em;
  height: 1.3333em;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  svg {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
}
