/* Font sizes */
@use 'sass:math';

$root-size: 16; // Root Element font size, used to generate rem units
$base-font-size: 14; // Base size ex: paragraphs
$mobile-base-font-size: 12; // base mobile size

/* Document sizes */
$desktop-width: 900px;
$mobile-width: 768px; // mobile size
$max-height: 1024px; // max doc size

$controllers-width: 468px;
$controllers-height: 290px;
$container-width: 344px;
$container-height: 250px;
$container-max-height: 700px;
$brand-width: 255px;
$brand-mobile-width: 225px;
$brand-height: $brand-width * math.div(151, 1534);
$brand-mobile-height: $brand-mobile-width * math.div(151, 1534);

/* COLORS */
// $background-color: #f2f2f2;
$background-color: #f4f5f8;
$secondary-color: #2100f5;
$warning-color: #ffae00;
$primary-color: #000;
$border-color: scale-color($background-color, $lightness: -15%);
$ui-handlers-color: scale-color($background-color, $lightness: 20%);
$light-grey: #555;
$selection: #ffde24ab;
$transparent: transparentize(white, 1);
$transparent-white: transparentize(white, 0.5);
$transparent-black: transparentize(black, 0.8);
$transparent-ui: transparentize($background-color, 0.8);
$shadow: 0 0 8px 6px $transparent-black;

/* Transitions */
$transition: 0.2s;
$transition-long: 0.78s;
$transition-short: 0.1s;

/* Gutter */
$desktop-vertical-space: 35px;
$mobile-vertical-space: 20px;
$mobile-gutter: 16px;
$desktop-gutter: 20px;
