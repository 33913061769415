@import '../../styles/importer';

.controllers {
  @include content-container;

  // border: 1px solid blue;
  @include desktop {
    height: 90%;
  }
}

.buttoncontainer {
  @include flexcenter-column;

  width: 100%;
}
