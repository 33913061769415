@import 'variables';
@import 'functions';

/* Import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap');

$font-size: rem-px($base-font-size);
$mobile-font-size: rem-px($mobile-base-font-size);

html {
  font-size: #{$root-size}px;
  tab-size: 4; // Tabs appear the same on the web as in a typical editor
  word-break: break-all; // Words break to prevent overflow
}

body {
  font-family: 'Work Sans', sans-serif; // Set up the font family
  font-feature-settings: 'liga' 1;
  font-size: $font-size; // use rem units for the size
  -webkit-font-smoothing: antialiased; // Safari specific smoothing
  -moz-osx-font-smoothing: grayscale; // mozila specific smoothing: firefox has terrible subsampling
  line-height: 1.65; // Text has a comfortable line height in all browsers
  -webkit-tap-highlight-color: transparent; // No stupid touch highlights
  -webkit-touch-callout: none; // When a target is touched and held on iOS, Safari displays a callout information about the link
  user-select: none; // Do not allow users to select text from the app

  @include mobile {
    font-size: $mobile-font-size;
  }
}

// Monospace shenanigans
code,
kbd,
pre,
samp {
  font-family: 'Fira Mono', monospace;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5 {
  width: 100%;
  font-weight: 700;
  letter-spacing: em-letterspacing(10);
  line-height: 0.9;
  text-transform: uppercase;
}

h1 {
  font-size: 2.488em;
  letter-spacing: em-letterspacing(-10);
}

h2 {
  font-size: 2.074em;
}

h3 {
  font-size: 1.728em;
}

h4 {
  font-size: 1.44em;
}

h5 {
  font-size: 1.2em;
}

small,
.text_small {
  font-size: 0.833em;
}

p {
  font-size: 1em;
  letter-spacing: em-letterspacing(-3);
  line-height: 1.5;
  opacity: 0.7;
  text-align: center;
  text-align: left;
  text-rendering: optimizelegibility;
  white-space: pre-line;

  @include mobile {
    letter-spacing: em-letterspacing(0);
    line-height: 1.64;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-bottom: 0;
  }
}
