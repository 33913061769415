@import '../../styles/importer';

$height: 2em;

.container {
  @include layer;

  pointer-events: none;
  transform: translateY($height * 0.5);
}

.content {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $height;
  border-radius: $height;
  background-color: $warning-color;
  color: $primary-color;
  font-weight: 800;
  line-height: $height;
  pointer-events: none;
  text-align: center;
  text-transform: uppercase;
  transition: all ease-in 0.3s;
}

.hidden {
  composes: content;
  bottom: -$height;
}
