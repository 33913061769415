@import '../../styles/importer';

.container {
  @include flexcenter-column;
  @include flex-no-grow;

  position: relative;
  z-index: 5;
  padding: 16px;
  background-color: $background-color;

  @include desktop {
    width: $controllers-width;
    height: 100%;
  }

  @include mobile {
    width: 100%;
    height: $controllers-height;
  }
}

.cta {
  @include content-container;

  @include desktop {
    height: $container-width;
  }

  @include mobile {
    max-height: calc($container-height - 40px);
  }

  p {
    margin: 0;
    font-size: 22px;
    font-style: italic;
    line-height: 26px;
    text-align: left;
  }

  h1 {
    line-height: 36px;

    > p > span {
      font-weight: 400;
      opacity: 0.8;
      text-decoration: line-through;

      &::after {
        content: ' ';
      }
    }
  }

  > div {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}
