@import '../../styles/importer';

$radio-height: 100px;
$radio-elem-size: 24px;
$radio-checked-size: 10px;
$radio-checked-color: $secondary-color;
$radio-background-color: $ui-handlers-color;
$radio-border: 1px solid $border-color;
$variant-select-border: 1px solid $radio-checked-color;
$round-size: 5px;
$variant-bg: white;
$variant-height: 60px;

.variantButton {
  display: flex;
  width: 100%;
  height: $variant-height;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 10px;
  border-radius: $round-size;
  margin-right: $round-size;
  background-color: $variant-bg;
  font-size: 12px;
  white-space: nowrap;

  &.selected {
    border: $variant-select-border;
  }

  &:last-child {
    margin-right: 0;
  }

  h2 {
    margin-bottom: 4px;
    font-size: 0.8em;
    line-height: 1;
  }

  h3 {
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.1;
  }

  h4 {
    margin-bottom: 0;
    color: #595b5e;
    font-size: 1;
    font-weight: 400;
    line-height: 1;
    text-decoration: line-through;
  }

  @include click;
}

.soldOut {
  opacity: 0.4;
  pointer-events: none;
}

.RadioButton {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .radio {
    display: flex;
    width: $radio-elem-size;
    height: $radio-elem-size;
    align-items: center;
    justify-content: center;
    border: $radio-border;
    border-radius: 100%;
    background: $radio-background-color;
    transition: border 0.25s linear;

    @include click;
  }

  .label {
    display: block;
    flex-grow: 2;
    margin: 10px;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    opacity: 0.7;
    transition: all 0.25s linear;

    h2 {
      margin-bottom: 0.3em;
      font-size: 0.8em;
      line-height: 1;
    }

    h3 {
      font-size: 0.7em;
      line-height: 1.5;
    }

    h4 {
      color: $radio-checked-color;
      font-size: 0.6em;
      font-weight: 700;
      line-height: 1.5;
    }
  }

  &.soldOut {
    .label {
      h4 {
        color: $primary-color;
      }
    }
  }

  .check {
    display: block;
    width: $radio-checked-size;
    height: $radio-checked-size;
    border-radius: 100%;
    background-color: $radio-background-color;
    transition: background-color 0.25s linear;
  }

  .selected {
    background-color: $radio-checked-color;
  }

  &:hover {
    .radio {
      border: 1px solid mix($radio-checked-color, $border-color, 50%);
    }

    .label {
      opacity: 1;
    }
  }
}
